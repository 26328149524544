import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { slideshow, fade, imgFormatting, prev, next, container, selected, dot } from './Carousel.module.scss';

const Carousel = ({ carouselImages }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const changeSlideIndex = (n) => {
    if (n > carouselImages.length - 1) {
      setSlideIndex(0);
    } else if (n < 0) {
      setSlideIndex(carouselImages.length - 1);
    } else {
      setSlideIndex(n);
    }
  };

  const onEnter = (e, n) => {
    if (e.keyCode === 13) {
      changeSlideIndex(n);
    }
  };

  return (
    <>
      <div className={slideshow}>
        {carouselImages.map((image, idx) => (
          <figure key={idx} className={`image is-4by3 ${fade} ${idx === slideIndex ? 'is-block' : 'is-hidden'}`}>
            <img className={`${imgFormatting} has-background-white-bis`} src={image} alt="" />
          </figure>
        ))}
        <span
          className={`icon ${prev} secondary`}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => onEnter(e, slideIndex - 1)}
          onClick={() => changeSlideIndex(slideIndex - 1)}
          aria-label="Carousel previous"
        >
          <FontAwesomeIcon icon={faAngleLeft} size="lg" />
        </span>
        <span
          className={`icon ${next} secondary`}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => onEnter(e, slideIndex - 1)}
          onClick={() => changeSlideIndex(slideIndex + 1)}
          aria-label="Carousel next"
        >
          <FontAwesomeIcon icon={faAngleRight} size="lg" />
        </span>
      </div>

      <div className={`container mt-4 ${container}`}>
        {carouselImages.map((image, idx) => (
          <div
            className={`${idx === slideIndex && selected} ${dot}`}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => onEnter(e, slideIndex - 1)}
            onClick={() => changeSlideIndex(idx)}
            key={idx}
            aria-label="Carousel selected image"
          >
            <figure className="image is-square">
              <img className={`${imgFormatting} has-background-white-bis`} src={image} alt="" />
            </figure>
          </div>
        ))}
      </div>
    </>
  );
};

Carousel.propTypes = {
  carouselImages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default Carousel;
