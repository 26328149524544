import dayjs from 'dayjs';

/**
 * Returns a formatted string showing the price with currency and amount.
 * @param {string} currency - the currency associated with the provided amount
 * @param {number} amount - the amount of currency in the smallest fractional monetary unit
 * @returns {string} - formatted string showing the price with currency and amount
 */
export const fmtPrice = (currency, amount) => `${currency.toUpperCase()}$ ${(amount / 100).toFixed(2)}`;

/**
 * Returns the value associated with the given cookie key.
 * @param {string} key - cookie key to be found
 * @returns {string} - value associated to the provided cookie key
 */
export const getCookie = (key) => {
  try {
    return document.cookie
      .split('; ') // split string into 'key=value' elements
      .find((el) => el.trim().startsWith(key)) // find element with given key
      .split('=')[1]; // return the value
  } catch (err) {
    return null;
  }
};

export const getExpiraton = () => dayjs().add(1, 'day');
