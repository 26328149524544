import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown, faDownload } from '@fortawesome/free-solid-svg-icons';

import { getSrc } from 'gatsby-plugin-image';
import { CartContext } from '../../CartProvider';
import MarkdownContent from '../../utils/MarkdownContent';
import Carousel from '../../Carousel/Carousel';
import { fmtPrice, getExpiraton } from '../../../utils';
import { addedToCart } from './ProductPageTemplate.module.scss';

const ProductPageTemplate = ({ productId, edges, title, images, content, maxQuantity, pdf, isPreview }) => {
  // --- Data Formatting --- //

  // format images
  const fmtImages = isPreview ? images : images.map((image) => getSrc(image));

  // format product by currency
  const item = {};

  const options = new Set();

  if (isPreview) {
    // dummy data
    item.cad = { id: 'id', price: 1000 };
  } else {
    // This is used to set the initial price of the item and also to fill the option selection dropdown
    for (const { node } of edges) {
      let key = node.currency;
      if (node.nickname) {
        options.add(node.nickname);
        key += `:${node.nickname}`;
      }

      item[key] = { id: node.id, price: node.unit_amount };
    }
  }

  const optionTags = [];

  options.forEach((el) => {
    optionTags.push(<option key={el} label={el} value={el} />);
  });

  // format quantity dropdown
  const quantities = [];

  for (let i = 1; i <= maxQuantity; i += 1) {
    quantities.push(<option key={i} label={i} value={i} />);
  }

  // --- State --- //

  let cartInfo;
  let setCartInfo;
  let currency = 'cad';

  // useContext interferes with preview mode
  if (!isPreview) {
    ({ cartInfo, setCartInfo, currency } = useContext(CartContext));
  }

  const [quantity, setQuantity] = useState(1);

  const [option, setOption] = useState(options.values().next().value || null);

  const currentKey = option ? `${currency}:${option}` : currency;

  // --- Animations --- //

  const controls = useAnimation();

  // variants for div and svg tag
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: [1, 0], transition: { duration: 2, ease: 'easeIn' } }
  };

  // variants for path tags
  const pathVariants = {
    visible: { pathLength: [0, 1, 0], transition: { duration: 2.5, ease: 'easeInOut' } }
  };

  // --- Methods --- //

  /**
   * Adds item to the users cart, or acts as a dummy function in preview mode.
   */
  const addToCart = () => {
    if (isPreview) {
      return;
    }

    let filteredItem = {};

    if (option) {
      for (const key in item) {
        if ({}.hasOwnProperty.call(item, key)) {
          const index = key.indexOf(`:${option}`);

          if (index !== -1) {
            const newKey = key.substring(0, index);
            filteredItem[newKey] = item[key];
          }
        }
      }
    } else {
      filteredItem = item;
    }

    const prodId = option ? `${productId}:${option}` : productId;
    const newCartInfo = {
      ...cartInfo,
      [prodId]: {
        item: filteredItem,
        quantity,
        title,
        option,
        image: fmtImages[0],
        link: window.location.pathname
      }
    };
    setCartInfo(newCartInfo);
    const expires = getExpiraton();

    localStorage.setItem('cartInfo', JSON.stringify({ cartInfo: newCartInfo, expires }));
    // after adding the item run the 'Added to Cart' animation
    controls.start('visible');
  };

  return (
    <main id="container" className="container">
      <div className="columns">
        <div className="column is-two-thirds">
          <Carousel carouselImages={fmtImages} />
        </div>
        <div className="column is-one-third">
          <h1 className="title is-3 is-spaced">{title}</h1>
          {pdf && !item[currentKey].price ? (
            <p className="title is-2 has-text-success is-italic">Free</p>
          ) : (
            <p className="subtitle is-4 is-italic">{fmtPrice(currency, item[currentKey].price)}</p>
          )}
          {!pdf && options.size > 0 && (
            <div className="field">
              <div className="label">
                <label className="subtitle is-6" htmlFor="option-select">
                  Options:
                </label>
              </div>
              <div className="control">
                <div className="select is-fullwidth mb-4">
                  <select id="option-select" value={option} onChange={(e) => setOption(e.currentTarget.value)}>
                    {optionTags}
                  </select>
                </div>
              </div>
            </div>
          )}
          {quantities.length > 1 && (
            <div className="field">
              <div className="label">
                <label className="subtitle is-6" htmlFor="quantity-select">
                  Quantity:
                </label>
              </div>
              <div className="control">
                <div className="select is-fullwidth mb-4">
                  <select
                    id="quantity-select"
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.currentTarget.value))}
                  >
                    {quantities}
                  </select>
                </div>
              </div>
            </div>
          )}
          <div className="field">
            <div className="control">
              {pdf && !item[currentKey].price ? (
                <a className="button is-fullwidth mb-4 background-secondary" href={pdf} type="button" download>
                  <span className="icon">
                    <FontAwesomeIcon icon={faDownload} />
                  </span>
                  <span>Download</span>
                </a>
              ) : (
                <>
                  <div className={`${addedToCart} my-2`}>
                    <motion.svg
                      initial="hidden"
                      animate={controls}
                      variants={variants}
                      className="mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 54 54"
                      height="1.5rem"
                    >
                      <motion.path
                        variants={pathVariants}
                        fill="none"
                        d="M 1, 26 a 25,25 0 1,0 50,0 a 25,25 0 1,0 -50,0"
                      />
                      <motion.path variants={pathVariants} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </motion.svg>
                    <motion.strong initial="hidden" animate={controls} variants={variants} className="has-text-success">
                      Added to Cart.
                    </motion.strong>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                    className="button background-secondary is-medium is-fullwidth"
                    onClick={addToCart}
                    type="submit"
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={faCartArrowDown} />
                    </span>
                    <span>Add to Cart</span>
                  </motion.button>
                </>
              )}
            </div>
          </div>
          <MarkdownContent content={content} isPreview={isPreview} />
        </div>
      </div>
    </main>
  );
};

ProductPageTemplate.defaultProps = {
  edges: null,
  isPreview: false,
  maxQuantity: 1,
  pdf: null
};

ProductPageTemplate.propTypes = {
  productId: PropTypes.string.isRequired,
  edges: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired).isRequired,
  content: PropTypes.string.isRequired,
  isPreview: PropTypes.bool,
  maxQuantity: PropTypes.number,
  pdf: PropTypes.string
};

export default ProductPageTemplate;
