import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import '../main.scss';
import Wrapper from '../components/Wrapper/Wrapper';
import ProductPageTemplate from '../components/Templates/Product/ProductPageTemplate';
import SEO from '../components/SEO';

const GuidePage = ({ data, pageContext }) => {
  // extract needed params
  const { productId } = pageContext;
  const { html, frontmatter } = data.markdownRemark;
  const { title, images, pdf } = frontmatter;
  const { edges } = data.allStripePrice;

  return (
    <Wrapper>
      <SEO />
      <ProductPageTemplate
        productId={productId}
        edges={edges}
        title={title}
        images={images}
        content={html}
        pdf={pdf.publicURL}
      />
    </Wrapper>
  );
};

GuidePage.propTypes = {
  data: PropTypes.shape({ allStripePrice: PropTypes.object.isRequired, markdownRemark: PropTypes.object.isRequired })
    .isRequired,
  pageContext: PropTypes.shape({
    productId: PropTypes.string.isRequired
  }).isRequired
};

export default GuidePage;

export const query = graphql`
  query GuidePageByProductId($productId: String!) {
    allStripePrice(filter: { active: { eq: true }, product: { id: { eq: $productId } } }) {
      edges {
        node {
          id
          unit_amount
          currency
        }
      }
    }
    markdownRemark(frontmatter: { productId: { eq: $productId } }) {
      html
      frontmatter {
        title
        pdf {
          publicURL
        }
        images {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 856)
          }
        }
      }
    }
  }
`;
